import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../../layouts";
import { Block, GetStartedBlock, MeetLeaders, ApplyBlock } from '../../components';

import BattleBackground from "../../images/War_3_1.svg";

import "../../global/styles/page-styles/apply-page.scss";

const LeadersApplicationPage = () => {
    return (
        <>
            <Helmet>
                <title>Partie | Apply</title>
                <html lang="en" />
                <meta name="description" content="Apply to be a Partie Leader Today!" />
            </Helmet>
            <PageLayout className="apply-page">
                <Block centered hero lazy>
                    <h2 className="partie-block__title">Lead By Example</h2>
                    <p className="partie-block__description">
                        Leaders uphold the values of our platform in everything they do, <br className="hidden" />
                        maintaining a high standard for quality, community engagement<br className="hidden" />
                        and positive play.
                    </p>
                    <div className="main-page-hero-background" aria-hidden>
                        <img src={BattleBackground} className="battle-background" alt="battlefield" aria-hidden />
                    </div>
                </Block>
                <MeetLeaders />
                <ApplyBlock />
                <GetStartedBlock />
            </PageLayout>
        </>
    );
};

export default LeadersApplicationPage;